<template src="./index.html"> </template>

<script>
import { mapState, mapActions } from 'vuex'
import Banner from '_c/banner/Index'
import MainListV2 from '_c/mainlistv2/Index'
import CategorysBoxV2 from '_c/CategorysBoxV2'
import ContactTip from '_c/ContactTip'
import { getUrlParma } from '../../util/jumpPage'
import { getQueryVariable } from '../../util/getQueryVariable'

const SHORTKEY_MODULE_TYPE = 'tid'
const SHORTKEY_SUB_TYPE = 'stid'
const COMMUNITON_HARDWARE_TYPE_ARRAY = [1, 2]

export default {
  async asyncData({ store, route }) {
    const PAGE = store.state.commonData.pageConfig['module']
    const DISPATCH = store.dispatch
    const CURRENTPAGE = store.state.route.name
    const QUERY = store.state.route.query
    let subtype = ''
    if (+QUERY[SHORTKEY_MODULE_TYPE] > 0) {
      subtype = COMMUNITON_HARDWARE_TYPE_ARRAY.includes(+QUERY[SHORTKEY_MODULE_TYPE]) ? 'communicationTypeId' : 'sceneTypeId'
    }
    
    return await Promise.all([
      //DISPATCH('componentCategorys/getModuleCategories'),
      DISPATCH('commonData/setCurrentPage', CURRENTPAGE),
      DISPATCH('componentCategorys/getCategorysV2'),
      // DISPATCH('componentCategorys/setModuleType', {
      //   moduleTypeId: +QUERY[SHORTKEY_MODULE_TYPE] || 0,
      //   subTypeId: +QUERY[SHORTKEY_SUB_TYPE] || 0,
      // }),
      DISPATCH('componentCategorys/setTypeId',{
        type: 'hardwareTypeId',
        id: +QUERY[SHORTKEY_MODULE_TYPE] || 0
      }),
      DISPATCH('componentCategorys/setTypeId',{
        type: subtype,
        id: +QUERY[SHORTKEY_SUB_TYPE]
      }),
      DISPATCH('dataModule/getChosenList', {
        actionType: 'MODULE_OPTION_LIST',
        limit: PAGE.chosenLimit,
        offset: 0,
      }),
      DISPATCH('dataModule/getMainList', {
        actionType: 'MODULE_LIST',
        limit: PAGE.mainLimit,
        offset: 0,
        name: QUERY.search || '',
        hardwareType: +QUERY[SHORTKEY_MODULE_TYPE] || 0,
        communicationType: +QUERY[SHORTKEY_SUB_TYPE] || 0,
        name: QUERY.search || '',
      }),
    ])
  },
  components: {
    Banner,
    MainListV2,
    CategorysBoxV2,
    ContactTip,
  },
  computed: {
    ...mapState('componentCategorys', {
      typeList: (s) => s.hardwareTypeList || [],
      communicationTypeList: (s) => s.communicationTypeList || [],
      sceneTypeList: (s) => s.sceneTypeList || [],
    }),
    dataFields() {
      const QUERY = this.$store.state.route.query
      let obj = [
        {
          field: 'hardwareTypeId',
          shortField: SHORTKEY_MODULE_TYPE,
          name: '硬件类型',
          options: this.typeList
        }
      ]
      if (QUERY[SHORTKEY_MODULE_TYPE] > 0) {
        const isCom = COMMUNITON_HARDWARE_TYPE_ARRAY.includes(+QUERY[SHORTKEY_MODULE_TYPE])
        obj.push({
          field: isCom ? 'communicationTypeId' : 'sceneTypeId',
          shortField: SHORTKEY_SUB_TYPE,
          name: isCom ? '通信类型' : '应用场景',
          options: isCom ? this.communicationTypeList : this.sceneTypeList
        })
      }
      return obj
    },
  },
}
</script>
